<template>
  <div class="preload">
    <vs-row style="height: 100vh">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <div>
            <img src="../../assets/images/logo/logo-somente-on.png"  class="elementToFadeInAndOut" style="width: 100px;"/>
          </div>
        </vs-col>
    </vs-row>
  </div>
</template>

<script>
export default {
created(){
    let portalConfig = JSON.parse(localStorage.getItem("lsApplicationConfig"));   
   
    if(portalConfig.redirectLogin) {
      setTimeout(()=>{window.location.replace(portalConfig.redirectLoginUrl)},2000)      
    }else{
      setTimeout(()=>{ this.$emit('mountView')},2000)   
    }
     
}
}
</script>

<style>
.elementToFadeInAndOut {  
  opacity:1;  
    animation: elementToFadeInAndOut 2s infinite;
  
}
@keyframes elementToFadeInAndOut { 
  0%   { opacity:1; }
  
  50%   { opacity:0.3; }
  
  100%  { opacity:1; }
}
</style>