<template>
  <div>
    <div v-if="loadSSO">
      <load-fade @mountView="loadSSO=false"/>
    </div>
    <div v-else class="layout--full-page">
      
      <div
        class="h-screen w-full bg-img"
        :style="{ 'background-image': 'url(' + backgroundImgUrl + ')' }"
      ></div>
      <div class="bg-mask">
        <div
          class="figure"
        ></div>
      </div>
      <div class="top-nav"></div>
      <vs-navbar class="nabar" color="white">
        <div class="container" slot="title">
          <div class="vs-navbar--title">
            <img :src="logoUrl" class="img-logo" width="192" />
          </div>
        </div>
      </vs-navbar>

      <div class="container w-full max-w-full py-12 md:py-16 md:px-16 md:m-0">
        <router-view></router-view>
      </div>
    </div>
  </div>  
</template>

<script>
import environment from "../../environment";
import loadFade from "../../components/initiLoadingAnimation/index.vue";

export default {
  components:{
    loadFade
  },
  data() {
    return {
      applicationConfig: {},
      loadSSO:false      
    };
  },
  watch: {
    color() {
      this.hexToRgb(this.color);
    }
  },
  computed: {
    logoUrl: {
      get() {
        const url = (((this.applicationConfig || {}).layout || {}).common || {})
          .logo;
        if (url) return url;
        else return require("@/assets/images/logo/logo.png");
      }
    },   
    backgroundImgUrl: {
      get() {
        const url = (((this.applicationConfig || {}).layout || {}).login || {})
          .backgroundImageUrl;
        if (url) return url;
        else {
          if (this.$route.name === "page-login-assessor")
            return require("@/assets/images/pages/businessmen-reflection.jpg");
          return require("@/assets/images/pages/new-login-bg.png");
        }
      }
    }
  },
  beforeMount() {
    this.applicationConfig = environment.getApplicationConfig();
    this.$route.path.split('/')[1] == 'login' ? this.loadSSO = true : this.loadSSO=false     
  }
};
</script>
<style lang="scss" scoped>
$hr: rgba(var(--vs-warning), 1);

.bg-img {
  background-attachment: fixed;
  position: fixed;
  overflow-y: scroll;
}

.bg-mask {
  background-color: rgba(68, 68, 68, 0.51);
  height: 100vh;
  width: 100vw;
  position: fixed;
  overflow: hidden;

  .figure {
    content: "";
    background-color:rgba(var(--vs-figureColor), 0.6);
    border-radius: 45px;
    height: 140%;
    right: -317px;
    top: -57px;
    transform: rotate(30deg);
    position: absolute;
    width: 57vw;
  }

  &::after {
    content: "";
    border: solid 50px rgba(119, 195, 252, 0);
    border-radius: 100%;
    bottom: -100px;
    height: 200px;
    left: -82px;
    position: absolute;
    width: 200px;
  }
}

.top-nav {
  background: rgba(var(--vs-primary), 1);
  height: 22px;
  position: relative;
  width: 100%;
}

.vs-navbar--title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 95px;
}

.img-logo {
  margin-left: 2rem;
  position: absolute;
}

.container {
  position: relative;
  background: transparent;
}

@media only screen and (max-width: 768px) {
  .bg-mask {
    display: none;
  }
}
</style>
