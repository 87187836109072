var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.loadSSO
      ? _c(
          "div",
          [
            _c("load-fade", {
              on: {
                mountView: function ($event) {
                  _vm.loadSSO = false
                },
              },
            }),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "layout--full-page" },
          [
            _c("div", {
              staticClass: "h-screen w-full bg-img",
              style: {
                "background-image": "url(" + _vm.backgroundImgUrl + ")",
              },
            }),
            _vm._m(0),
            _c("div", { staticClass: "top-nav" }),
            _c(
              "vs-navbar",
              { staticClass: "nabar", attrs: { color: "white" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "container",
                    attrs: { slot: "title" },
                    slot: "title",
                  },
                  [
                    _c("div", { staticClass: "vs-navbar--title" }, [
                      _c("img", {
                        staticClass: "img-logo",
                        attrs: { src: _vm.logoUrl, width: "192" },
                      }),
                    ]),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "container w-full max-w-full py-12 md:py-16 md:px-16 md:m-0",
              },
              [_c("router-view")],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bg-mask" }, [
      _c("div", { staticClass: "figure" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }